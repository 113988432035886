<template>
    <tbody>
        <tr>
            <td :colspan="colspan" class="uk-text-center">No entries found</td>
        </tr>
    </tbody>
</template>

<script>
export default {
    props: {
        colspan: {
            required: true,
            type: Number,
        }
    },
};
</script>
