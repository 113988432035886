<template>
  <div class="uk-container uk-container-expand uk-margin-top card-scrollable">
    <div class="uk-margin">
      <div uk-grid class="uk-grid-small">
        <div class="uk-inline">
          <button
            class="uk-button uk-button-small-right uk-button-default"
            type="button"
            @click="backCompanyDetail"
          >
            <span class="uk-margin-small-right" uk-icon="icon: arrow-left"></span>
            Back
          </button>
        </div>
        <div class="uk-inline uk-width-medium uk-margin-small-right">
          <multiselect
              v-model="meta.company_office_id"
              placeholder="Search by Property"
              label="name"
              name="office"
              track-by="name"
              :options="office_options"
              :searchable="true"
              :loading='isFetching'
              :internal-search="true"
            >
            </multiselect>
        </div>
        <div class="uk-form-controls uk-width-auto">
          <select class="uk-select" id="form-horizontal-select" v-model="meta.status" @change="(e) => changeMeta(e, 'status')">
            <option :value=null>Select status</option>
            <option :value="1">Submitted</option>
            <option :value="0">In Progress</option>
            <option :value="2">Completed</option>
          </select>
        </div>
        <div class="uk-inline">
          <button style="width:100px; cursor:pointer; height:40px" class="uk-button-primary" @click="showModal('initiationFormModal')"
          v-if="consist(user_cred.roles, ['admin-bd','qwe123'])">Create</button>
        </div>
      </div>
    </div>

    <div class="uk-card uk-card-default uk-margin">
      <div class="uk-overflow-auto">
        <table class="uk-table uk-table-small uk-table-divider uk-table-striped">
          <thead>
            <tr>
              <th class="uk-text-center uk-width-medium">Office</th>
              <th class="uk-text-center uk-width-medium">Job Title</th>
              <th class="uk-text-center uk-width-small">Manpower</th>
              <th class="uk-text-center uk-width-small">Fulfilled<br/>Manpower</th>
              <th class="uk-text-center uk-width-medium">Start Date</th>
              <th class="uk-text-center uk-width-medium">Due Date</th>
              <th class="uk-text-center uk-width-medium">Start Work</th>
              <th class="uk-text-center uk-width-medium">PIC</th>
              <th class="uk-text-center uk-width-medium">SEO Keyword</th>
              <th class="uk-text-center uk-width-small">Status</th>
              <th class="uk-text-center uk-width-medium">Action</th>
            </tr>
          </thead>
          <tbody v-if="initiations.docs.length>0" class="uk-table-middle uk-text-center ">
            <tr v-for="(initiation, i) in initiations.docs" :key="i">
              <td>{{initiation.company_office_id ? initiation.company_office_id.name ? initiation.company_office_id.name : initiation.company_office_id._id : 'Not Found Office'}}</td>
              <td>{{initiation.role_id ? initiation.role_id.name ? initiation.role_id.name : initiation.role_id._id : 'Not Found Job Title'}}</td>
              <td>{{initiation.manpower}}</td>
              <td>{{initiation.fulfilled_manpower}}</td>
              <td>{{localeDateString(initiation.start_date)}}</td>
              <td>{{localeDateString(initiation.due_date)}}</td>
              <td>{{localeDateString(initiation.start_work)}}</td>
              <td>
                <span v-for="(pic, i) in initiation.pic_ids" :key="i">
                  {{pic ? pic.fullname ? pic.fullname : pic._id : 'Not Found PIC'}}<span v-if="initiation.pic_ids[i+1]">, </span>
                </span>
              </td>
              <td>{{initiation.seo_keyword ? initiation.seo_keyword : '-'}}</td>
              <td>
                <div
                  class="label uk-margin-auto" e69716
                  :style="`background-color: ${initiation.status === 1 ? '#e69716' : initiation.status === 2 ? 'gray' : '#00cc00' }`">
                  {{initiation.status === 1 ? 'Submitted' : initiation.status === 2 ? 'Completed' : 'In Progress'}}
                </div>
              </td>
              <td>
                <!-- <button
                  style="width:80px; cursor:pointer; height:30px"
                  class="uk-button-primary uk-margin-small-right"
                  @click="fillForm(initiation, 'initiationDetailModal')">
                  See Detail
                </button> -->
                <button
                  style="width:80px; cursor:pointer; margin-bottom:8px; height:30px; background:#333747"
                  class="uk-button-primary"
                  v-clipboard:copy="initiation._id"
                  v-clipboard:success="onCopy">
                  Copy ID
                </button><br/>
                <button
                  style="width:80px; cursor:pointer; height:30px"
                  class="uk-button-default uk-margin-small-right"
                  @click="editInitiation(initiation)"
                  v-if="consist(user_cred.roles, ['admin-bd','qwe123'])">
                  Edit
                </button>
                <button
                  v-if="isLoading"
                  style="width:80px; cursor:pointer; height:30px"
                  class="uk-button-danger" type="button" disabled>
                  <div uk-spinner></div>
                </button>
                <button
                  v-else-if="consist(user_cred.roles, ['admin-bd','qwe123'])"
                  style="width:80px; cursor:pointer; height:30px"
                  class="uk-button-danger"
                  @click="delInitiation(initiation)">
                  Delete
                </button>
              </td>
            </tr>
          </tbody>
          <empty-table v-else :colspan="11" />
        </table>
      </div>
      <pagination
        :total-data="initiations.totalDocs"
        :change-limit="changeLimit"
        :change-page="changePage"
      />
    </div>

    <div id="initiationDetailModal" uk-modal esc-close="false" bg-close="false">
      <div class="uk-modal-dialog">
        <button class="uk-modal-close-default" type="button" uk-close @click="hideModal('initiationDetailModal')"></button>
        <div class="uk-modal-header">
          <h3 v-if="formIsDelete" >Delete Initiation?</h3>
          <h3 v-else>Initiation Details {{initiationForm.company_office_id.name}} ({{initiationForm.company_office_id.code}})</h3>
        </div>
        <div class="uk-modal-body">
          <div class="uk-margin-small">
            <div class="uk-margin-bottom">
              <p>Office: <span class="uk-text-bold">{{initiationForm.company_office_id.name}}</span></p>
              <p>Job Title Needed: <span class="uk-text-bold">{{initiationForm.role_id.name}}</span></p>
              <p>Manpower: <span class="uk-text-bold">{{initiationForm.manpower}} Workers</span></p>
              <p>Start Date: <span class="uk-text-bold">{{localeDateString(initiationForm.start_date)}}</span></p>
              <p>Due Date: <span class="uk-text-bold">{{localeDateString(initiationForm.due_date)}}</span></p>
              <p>Start Work: <span class="uk-text-bold">{{localeDateString(initiationForm.start_work)}}</span></p>
            </div>
            <div class="uk-margin-top">
              <p>PICs:
                <span v-for="(pic, i) in initiationForm.pic_ids" :key="i" class="uk-text-bold">{{`${pic.fullname}`}}
                  <span v-if="initiationForm.pic_ids[i+1]"> , </span>
                </span>
                </p>
            </div>

          </div>
        </div>
        <div class="uk-modal-footer uk-text-right">
          <button v-if="formIsDelete" class="uk-button uk-button-danger" type="button" @click="markInitiationDeleted">Delete</button>
          <button v-else class="uk-button uk-button-primary" type="button" @click="hideModal('initiationDetailModal')">OK</button>
        </div>
      </div>
    </div>

    <div id="initiationFormModal" class="uk-modal" uk-modal esc-close="false" bg-close="false">
      <div class="uk-modal-dialog">
        <button class="uk-modal-close-default" type="button" uk-close @click="hideModal('initiationFormModal')"></button>
        <div class="uk-modal-header">
          <h2 class="uk-modal-title">Initiation Form</h2>
        </div>
        <div class="uk-modal-body">

          <div class="uk-margin-small">
            <label class="uk-form-label">Company <b class="uk-text-danger">*</b></label>
            <multiselect
              v-model="initiationForm.company_id"
              placeholder="Type to search company"
              label="name"
              name="company"
              track-by="name"
              v-validate="'required'"
              :options="company_options"
              :disabled="true"
            >
            </multiselect>
            <span class="uk-text-small uk-text-danger" v-show="errors.has('company')">{{ errors.first('company') }}</span>
          </div>

          <div class="uk-margin-small">
            <label class="uk-form-label">Office (Property) <b class="uk-text-danger">*</b></label>
            <multiselect
              v-model="initiationForm.company_office_id"
              placeholder="Type to search office"
              label="name"
              name="office"
              track-by="name"
              v-validate="'required'"
              :options="office_options"
              :searchable="true"
              :loading='isFetching'
              :internal-search="true"
            >
            </multiselect>
            <span class="uk-text-small uk-text-danger" v-show="errors.has('office')">{{ errors.first('office') }}</span>
          </div>

          <div class="uk-margin-small">
            <label class="uk-form-label">Status <b class="uk-text-danger">*</b></label>
            <multiselect
              v-model="initiationForm.status"
              placeholder="Select Status"
              label="name"
              name="status"
              track-by="name"
              v-validate="'required'"
              :options="status_options">
            </multiselect>
            <span class="uk-text-small uk-text-danger" v-show="errors.has('status')">{{ errors.first('status') }}</span>
          </div>

          <hr class="uk-divider-icon">

          <div class="uk-flex uk-grid-large" uk-grid>
            <div class="uk-width-expand" >
              <div class="uk-margin-medium-bottom" >
                <div class="uk-margin-small">
                  <label class="uk-form-label">Job Title <b class="uk-text-danger">*</b></label>
                  <multiselect
                    v-model="initiationForm.role_id"
                    placeholder="Type to search role"
                    label="name"
                    name="role"
                    track-by="_id"
                    v-validate="'required'"
                    :allow-empty="false"
                    deselect-label="Can't remove this value"
                    :disabled="!initiationForm.company_office_id"
                    :options="role_options"
                    :searchable="true"
                    :loading='isFetching'
                    :internal-search="true"
                  >
                  </multiselect>
                  <span class="uk-text-small uk-text-danger" v-show="errors.has(`role`)">{{ errors.first(`role`) }}</span>
                </div>

                <div class="uk-margin-small">
                  <label class="uk-form-label">Start Date <b class="uk-text-danger">*</b></label>
                  <datetime
                    input-class="uk-input"
                    type="date"
                    :class="{'uk-form-danger': errors.has(`start_date`)}"
                    name="start_date"
                    v-model="initiationForm.start_date"
                    placeholder="Start Date"
                    v-validate="'required'"
                  ></datetime>
                  <span class="uk-text-small uk-text-danger" v-show="errors.has(`start_date`)">{{ errors.first(`start_date`) }}</span>
                </div>

                <div class="uk-margin-small">
                  <label class="uk-form-label">Due Date <b class="uk-text-danger">*</b></label>
                  <datetime
                    input-class="uk-input"
                    type="date"
                    :class="{'uk-form-danger': errors.has(`due_date`)}"
                    name="due_date"
                    v-model="initiationForm.due_date"
                    :min-datetime="minDate"
                    placeholder="Due Date"
                    v-validate="'required'"
                  ></datetime>
                  <span class="uk-text-small uk-text-danger" v-show="errors.has(`due_date`)">{{ errors.first(`due_date`) }}</span>
                </div>

                <div class="uk-margin-small">
                  <label class="uk-form-label">Start Work <b class="uk-text-danger">*</b></label>
                  <datetime
                    input-class="uk-input"
                    type="date"
                    :class="{'uk-form-danger': errors.has(`start_work`)}"
                    name="start_work"
                    v-model="initiationForm.start_work"
                    placeholder="Start Work"
                    v-validate="'required'"
                  ></datetime>
                  <span class="uk-text-small uk-text-danger" v-show="errors.has(`start_work`)">{{ errors.first(`start_work`) }}</span>
                </div>

                <div class="uk-margin-small">
                  <label class="uk-form-label">Manpower <b class="uk-text-danger">*</b></label>
                  <input
                    class="uk-input"
                    :class="{'uk-form-danger': errors.has(`manpower`)}"
                    name="manpower"
                    type="number"
                    v-model="initiationForm.manpower"
                    placeholder="Manpower"
                    v-validate="'required'">
                    <span class="uk-text-small uk-text-danger" v-show="errors.has(`manpower`)">{{ errors.first(`manpower`) }}</span>
                </div>

                <div class="uk-margin-small">
                  <label class="uk-form-label">PICs <b class="uk-text-danger">*</b></label>
                  <multiselect
                    v-model="initiationForm.pic_ids"
                    placeholder="Type to search role"
                    label="fullname"
                    name="pic"
                    track-by="_id"
                    v-validate="'required'"
                    :options="pic_options"
                    :searchable="true"
                    :loading='isFetching'
                    :internal-search="true"
                    :multiple="true"
                    :close-on-select="false"
                    :clear-on-select="false"
                  >
                  </multiselect>
                  <span class="uk-text-small uk-text-danger" v-show="errors.has(`pic`)">{{ errors.first(`pic`) }}</span>
                </div>

                <div class="uk-margin-small">
                  <label class="uk-form-label">SEO Keyword <b class="uk-text-danger">*</b></label>
                  <input
                    class="uk-input"
                    :class="{'uk-form-danger': errors.has(`seo`)}"
                    name="seo"
                    type="text"
                    v-model="initiationForm.seo_keyword"
                    placeholder="SEO Keyword"
                    v-validate="'required'">
                    <span class="uk-text-small uk-text-danger" v-show="errors.has(`seo`)">{{ errors.first(`seo`) }}</span>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div class="uk-modal-footer uk-text-right">
          <button v-if="isLoading" class="uk-button uk-button-primary" type="button" disabled><div uk-spinner></div></button>
          <button v-else class="uk-button uk-button-primary" type="button" @click="saveInitiation">Save</button>
        </div>
      </div>
    </div>

    </div>
</template>

<script>
import Pagination from '@/components/globals/Pagination';
import EmptyTable from '@/components/globals/tables/EmptyTable';
import { mapActions, mapGetters } from 'vuex';
import {
    notificationSuccess,
    notificationDanger
} from '@/utils/notification';
import { Datetime } from 'vue-datetime';
import { getUserLogin } from '@/utils/auth';
import moment from 'moment';

export default {
    data() {
        return {
            minDate: moment().format('YYYY-MM-DD'),
            user_cred: getUserLogin(),
            meta: {
                limit: 100,
                page: 1,
                status:null,
                is_deleted: false,
                company_office_id: '',
            },
            initiationForm: {
                company_id: '',
                company_office_id: '',
                status: '',
                role_id: '',
                start_date: '',
                due_date: '',
                start_work: '',
                manpower: '',
                pic_ids: [],
                seo_keyword: '',
            },
            formIsEdit: false,
            formIsDelete: false,
            isLoading: false,
            isFetching: false,
            company_options: [],
            office_options: [],
            role_options: [],
            status_options: [
                { name: 'In Progress', value: 0},
                { name: 'Submitted', value: 1},
                { name: 'Completed', value: 2}
            ],
            pic_options: [],
            company: {
                roles: [],
                offices: [],
            },
        };
    },
    components: {
        Pagination,
        EmptyTable,
        Datetime
    },
    computed: {
        ...mapGetters({
            initiations: 'initiation/initiations',
            company_data: 'company/company',
            users: 'user/users',
        }),
        metaModifier() {return this.meta;},
        selectedOffice() {return this.initiationForm.company_office_id;},
    },
    watch: {
        metaModifier: {
            handler: function() {
                this.setInitiationMeta();
            },
            deep:true
        },
        selectedOffice() {
            this.setRoleSettingOptions();
        },
    },
    async mounted() {
        await this.setCompanyDetailMeta();
        await this.setInitiationMeta();
        await this.setUser();
    },
    methods: {
        ...mapActions({
            getCompanyDetail: 'company/getCompanyDetail',
            getInitiations: 'initiation/getInitiations',
            createInitiation: 'initiation/createInitiation',
            updateInitiation: 'initiation/updateInitiation',
            deleteInitiation: 'initiation/deleteInitiation',
            listsUser: 'user/ListsUser',
            getPropertyRoleSettingNonPaginate: 'property_role_setting/getPropertyRoleSettingNonPaginate',
        }),
        async setRoleSettingOptions(){
            this.isFetching = true;
            this.role_options = await this.getPropertyRoleSettingNonPaginate({company_office_id: this.initiationForm.company_office_id._id});
            this.isFetching = false;
        },
        async setUser(){
            const result = await this.listsUser({ roles: 'bd-op', scope:'all' });
            this.pic_options = result;
        },
        async setCompanyDetailMeta() {
            await this.getCompanyDetail(this.$route.params.company_id.toString());
            this.company = this.company_data;
            this.initiationForm.company_id = {
                name: this.company_data.name,
                value: this.company_data._id
            };
            this.office_options = this.company_data.offices;
        },
        async setInitiationMeta() {
            await this.getInitiations({
                ...this.meta,
                company_id: this.$route.params.company_id.toString(),
                company_office_id: this.meta.company_office_id ? this.meta.company_office_id._id : '',
            });
        },
        changeMeta(e, prop) {
            this.meta = {
                ...this.meta,
                [prop]: e.target.value
            };
            this.setInitiationMeta(this.meta);
        },
        changeLimit(e) {
            this.meta = {
                ...this.meta,
                limit: e.target.value
            };
        },
        changePage(value) {
            this.meta = {
                ...this.meta,
                page: value
            };
        },
        localeDateString(date) {
            return date ? new Date(date).toLocaleDateString("id-ID", { year: 'numeric', month: 'long', day: 'numeric' }) : null;
        },
        async fillForm(initiation, modal) {
            this.initiationForm.id = initiation._id;
            this.initiationForm.role_id = initiation.role_id;
            this.initiationForm.company_office_id = initiation.company_office_id;
            this.initiationForm.status = this.status_options.find(
                e => e.value === initiation.status
            );
            this.initiationForm.start_date = initiation.start_date;
            this.initiationForm.due_date = initiation.due_date;
            this.initiationForm.start_work = initiation.start_work;
            this.initiationForm.manpower = initiation.manpower;
            this.initiationForm.seo_keyword = initiation.seo_keyword;
            for (const exist_pic of initiation.pic_ids) {
                const findPic = this.pic_options.find((user) => user?._id?.toString() == exist_pic?._id?.toString());
                if (findPic) {
                    this.initiationForm.pic_ids.push(findPic);
                }
            }

            this.showModal(modal);
        },
        async saveInitiation() {
            try {
                const validate = await this.$validator.validateAll();
                if (!validate || this.$validator.errors.any()) return;

                this.isLoading = true;

                let fix_pic = [];
                for (const sel_pic of this.initiationForm.pic_ids) {
                    fix_pic.push(sel_pic._id);
                }

                let submitForm = {
                    ...this.initiationForm,
                    company_id: this.initiationForm.company_id.value,
                    company_office_id: this.initiationForm.company_office_id._id,
                    role_id: this.initiationForm.role_id._id,
                    pic_ids: fix_pic,
                    status: this.initiationForm.status.value,
                };

                let response = null;
                if (this.formIsEdit) {
                    response = await this.updateInitiation(submitForm);
                } else {
                    response = await this.createInitiation(submitForm);
                }

                this.isLoading = false;
                if (response != null) {
                    notificationSuccess('Initiation saved!');
                    this.setInitiationMeta();
                    this.hideModal('initiationFormModal');
                } else {
                    notificationDanger('Failed to save initiation.');
                }
            } catch (err) {
                notificationDanger(err);
            }
        },
        async editInitiation(initiation) {
            this.formIsEdit = true;
            await this.fillForm(initiation, 'initiationFormModal');
        },
        delInitiation(initiation) {
            this.formIsDelete = true;
            this.fillForm(initiation, 'initiationDetailModal');
        },
        async markInitiationDeleted() {
            try {
                this.isLoading = true;

                const response = await this.deleteInitiation(this.initiationForm.id);

                this.isLoading = false;

                if (response && response.status === 'OK') {
                    notificationSuccess('Initiation deleted!');
                    this.hideModal('initiationDetailModal');
                    this.setInitiationMeta();
                } else {
                    notificationDanger('Failed to delete initiation.');
                }
            } catch (err) {
                notificationDanger(err);
            }
        },
        resetForm() {
            this.formIsEdit = false;
            this.formIsDelete = false;
            this.initiationForm = {
                company_id: this.initiationForm.company_id,
                company_office_id: '',
                status: '',
                role_id: '',
                start_date: '',
                due_date: '',
                start_work: '',
                manpower: '',
                pic_ids: [],
                seo_keyword: '',
            };
            this.role_options = [];
            this.$validator.reset();
        },
        async showModal(modal) {
            await window.UIkit.modal(`#${modal}`).show();
        },
        async hideModal(modal) {
            await window.UIkit.modal(`#${modal}`).hide();
            this.resetForm();
        },
        onCopy(e) {
            notificationSuccess('Copied to clipboard: ' + e.text);
        },
        backCompanyDetail() {
            this.$router.push(`/admin/mitra/company/detail/${this.$route.params.company_id.toString()}`);
        },
        consist(user_roles, value){
            return value.some(i => user_roles.includes(i));
        },
    }
};
</script>
